<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col class="text-center">
            <span class="page-title">
              GET PREPARED FOR OUR LAUNCHING PARTY
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <lottie-animation
                path="launch-lava-finaljson.json"
            />
          </v-col>
        </v-row>
        <v-row>
          <flip-countdown deadline="2022-03-01 00:00:00"></flip-countdown>
        </v-row>
      </v-container>

    </v-main>
    <v-footer
        absolute
        dark
        color="#153152"
        app
    >
      <v-container v-if="!subscribed">
        <v-row>
          <v-col v-if="!$vuetify.breakpoint.mobile" style="position: relative">
            <v-img
                style="position: absolute; left: 10%; top: 10%"
                contain
                width="92px"
                src="/img/cloud.svg"
            />
            <v-img
                style="position: absolute; right: 10%; bottom: 10%"
                contain
                width="62px"
                src="/img/heart.svg"
            />
          </v-col>
          <v-col>
            <v-container class="text-center" style="max-width: 476px">
              <v-row>
                <v-col>
                  <span class="footer-title">
                    GET INVITED
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <b>You will receive an invite to our launching party</b>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  First 10 participants will receive FREE rewards.
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-text-field
                      v-model="name"
                      class="centered-input"
                      placeholder="Enter your name"
                      background-color="rgba(69,92,111, 100)"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-text-field
                      v-model="email"
                      class="centered-input"
                      placeholder="Enter your email"
                      background-color="rgba(69,92,111, 100)"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-btn light color="#FFD194" depressed @click="subscribe" :disabled="name == '' || email == ''">
                    <span style="color: #153152; text-transform: none; font-weight: bold">Subscribe</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile" style="position: relative">
            <v-img
                style="position: absolute; right: 10%; top: 10%"
                contain
                width="130px"
                src="/img/gift.svg"
            />
            <v-img
                style="position: absolute; left: 10%; bottom: 10%"
                contain
                width="89px"
                src="/img/joystick.svg"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
          <v-row>
            <v-col class="text-center">
                  <span class="footer-title">
                    Thank you for signing up
                  </span>
            </v-col>
          </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import FlipCountdown from 'vue2-flip-countdown';

export default {
  name: 'App',
  components: {
    LottieAnimation,
    FlipCountdown
  },
  data: () => ({
    subscribed: false,
    name: '',
    email: ''
  }),
  created() {
  },
  methods: {
    async subscribe() {
      await this.storeFormData(this.db, this.name, this.email);
      this.subscribed = true;
    }
  }
};
</script>

<style lang="scss">
.v-main {
  min-height: 100vh;
  min-width: 100vw;
  background: rgb(112,225,245);
  background: linear-gradient(60deg, rgba(112,225,245,0.35) 0%, rgba(193,182,217,1) 50%, rgba(255,209,148,0.71) 100%);
}
.flip-card__top[data-v-cff81de8], .flip-card__bottom[data-v-cff81de8], .flip-card__back-bottom[data-v-cff81de8], .flip-card__back[data-v-cff81de8]::before, .flip-card__back[data-v-cff81de8]::after,
.flip-clock .flip span b {
  color: #2E4766 !important;
}

.flip-card__top[data-v-cff81de8], .flip-card__bottom[data-v-cff81de8], .flip-card__back-bottom[data-v-cff81de8], .flip-card__back[data-v-cff81de8]::before, .flip-card__back[data-v-cff81de8]::after,
.flip-clock.flip-card__top, .flip-card__bottom {
  background: rgba(186, 183, 215, 1) !important;
}

.flip-card__bottom[data-v-cff81de8], .flip-card__back-bottom[data-v-cff81de8], .flip-card__bottom-4digits[data-v-cff81de8], .flip-card__back-bottom-4digits[data-v-cff81de8] {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.flip-clock__slot {
  padding-top: 10px;
  text-transform: uppercase;
  color: #153152;
  font-weight: bold;
  font-size: 20px;
}
.flip-clock__piece flip {
  display: inline-block;
}
.page-title {
  color: #153152;
  font-weight: bold;
  font-size: 40px;
}
.centered-input input {
  text-align: center
}
.footer-title {
  font-weight: bold;
  font-size: 22px;
}
</style>