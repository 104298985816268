import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyDn_usgjdEo2OOe0YVGf9tiHeebqUDjK5g",
  authDomain: "fulmo-launch.firebaseapp.com",
  projectId: "fulmo-launch",
  storageBucket: "fulmo-launch.appspot.com",
  messagingSenderId: "917310395227",
  appId: "1:917310395227:web:3ca9e4cc55305c2aa5007e"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

Vue.config.productionTip = false

async function storeFormData(db, name, email) {
  const invitationCollection = collection(db, 'invitation');
  await addDoc(invitationCollection, {
    name,
    email,
    when: Date.now()
  });
}

Vue.prototype.db = db;
Vue.prototype.storeFormData = storeFormData;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
